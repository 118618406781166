import {useEffect, useState} from 'react';
import axios from 'axios';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import '../../design/css/index.css';
import '../../App.css';

const DesignSelector = ({customDesign, onDesignChange}) => {
    const handleCheckboxChange = (boolean) => {
        onDesignChange(boolean);
    };

    return (
        <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
            <label>
                <input
                    type="checkbox"
                    checked={customDesign === true}
                    onChange={() => handleCheckboxChange(!customDesign)}
                />
                Check if you want to create two pages for both EN and NL.
                Leave unchecked if language does not matter.
            </label>
        </div>
    );
};

function FormDialog({type, handleClick, id, general, text}) {
    const [open, setOpen] = useState(false);
    const [customDesign, setCustomDesign] = useState(false);
    const [displayName, setdisplayName] = useState("");

    const handleDesignChange = (boolean) => {
        setCustomDesign(boolean);
    };

    const callApi = (language, url) => {

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                groupId: general ? 0 : id,
                name: displayName,
                language: language,
                page: displayName,
                text: "",
                isGeneral: general ? 1 : 0,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // handleClick(team.ID);
                handleClose();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const addPage = () => {

        const apiUrl = `${localStorage.getItem('host')}/api/addPage`;
        if (!customDesign) {
            callApi("NA", apiUrl);
        } else {
            callApi("EN", apiUrl);
            callApi("NL", apiUrl);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <button
                style={{marginRight: "10px", marginBottom: "10px"}}
                className="btn btn-success"
                onClick={handleClickOpen}
            >
                {text}
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add page</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a new page :)
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Page display name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setdisplayName(e.target.value)}
                    />
                    {!general &&
                    <DesignSelector
                        customDesign={customDesign}
                        onDesignChange={handleDesignChange}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={addPage}>Submit</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function DropDown({title, buttons, children}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const mystyle = {
        textDecoration: "none",
        fontSize: "16px",
        textTransform: "uppercase",
        color: "black",
    };

    return (
        <div
            className={`dropdown${isOpen ? ' show' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <p
                type="button"
                className="dropdown-toggle"
                style={mystyle}
            >
                {title}
            </p>
            <div
                className={`dropdown-menu${isOpen ? ' show' : ''}`}
                style={{marginTop: "-20px", width: "20%"}}
            >
                {children}
            </div>
        </div>
    );
}


function Pages() {

    const [currentPage, setCurrentPage] = useState({});
    const [data, setData] = useState("");
    const [pages, setPages] = useState([]);
    const [pagesGroups, setPagesGroups] = useState([]);
    const [generalPages, setGeneralPages] = useState([]);
    const [showAddpage, setShowAddPage] = useState(false);
    const [showGeneral, setShowGeneral] = useState(false);
    const [groupId, setGroupId] = useState(0);

    useEffect(() => {
        axios.get(`${localStorage.getItem('host')}/api/getPagesGroups`)
          .then(response => {
            setPagesGroups(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    }, []);

    const fetchGeneralPages = async () => {
        axios.get(`${localStorage.getItem('host')}/api/getGeneralPages`)
          .then(response => {
            setGeneralPages(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    };

    // useEffect(() => {
    //     axios.get(`${localStorage.getItem('host')}/api/getText/all/lala`)
    //       .then(response => {
    //         setPages(response.data);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching data:', error);
    //       });
    // }, []);

    const fetchPages = async (groupId) => {
        try {
            console.log(localStorage.getItem('host'));
            const response = await axios.get(`${localStorage.getItem('host')}/api/getPagesByGroupId/${groupId}/all`);
            setPages(response.data);
        } catch (error) {
            console.error(`Error fetching pages for groupId ${groupId}:`, error);
        }
    };

    // const handleClick = (page, lan) => {

    //     axios.get(`${localStorage.getItem('host')}/api/getText/${page}/${lan}`)
    //     .then(response => {
    //         console.log(response)
    //         setData(response.data[0].text);
    //         setCurrentPage(response.data[0]);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching data:', error);
    //     }); 
    // }

    const handleClick = (page, lan) => {

        axios.get(`${localStorage.getItem('host')}/api/getPage/${page}/${lan}`)
        .then(response => {
            console.log(response)
            setData(response.data[0].text);
            setCurrentPage(response.data[0]);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        }); 
    }

    const handleSubmit = async (e) => {

        try {
          const response = await fetch(`${localStorage.getItem('host')}/api/setText`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ currentPage, data }),
            });

            if (response.ok) {
                alert('Text updated successfully');
            } else {
                alert('Failed to update text');
            }
        } catch (error) {
            console.error('Error updating text:', error);
        }
    };

    return (
        <>

            <div className="row">
                {pagesGroups.length > 0 ? (
                    pagesGroups.map((group, index) => (
                        <div key={index} className={"col-md-2"}>
                            <button className={`btn btn-primary`} onClick={() => {fetchPages(group.ID); setShowAddPage(true); setGroupId(group.ID); setShowGeneral(false);}}>{group.groupNameNL} / {group.groupNameEN}</button>
                        </div>
                    ))
                ) : (
                    <div className="col-md-12">
                        Loading...
                    </div>
                )}
            </div>
            <div className="row" style={{marginTop:"1vh"}}>
            {pages.map((item, index) => (
                <div className="col-md-2">
                        {item.page === "Algemeen" &&
                        <button className={`btn btn-primary`} key={index} onClick={() => {fetchGeneralPages(); setShowAddPage(true); setGroupId(item.groupId); setShowGeneral(true);}}>Algemeen</button>}
                        {item.page !== "Algemeen" &&
                        <button className={`btn btn-info`} key={index} onClick={() => {handleClick(item.page, item.language); setShowGeneral(false);}}>{item.page} {item.language ? item.language : ""}</button>}
                </div>))}
                {showAddpage &&
                <div className="col-md-2">
                    <FormDialog id={groupId} general={false} text={"Add page to header"}/>
                </div>}
                {showGeneral && generalPages.map((item, index) => (
                <div className="col-md-2">
                        <button className={`btn btn-info`} key={index} onClick={() => handleClick(item.page, item.language)}>{item.page} {item.language ? item.language : ""}</button>
                </div>))}
                {showGeneral &&
                <div className="col-md-2">
                    <FormDialog id={groupId} general={true} text={"Add new entry to Algemeen page"}/>
                </div>}
            </div>
            <div className="row" style={{marginTop:"1vh"}}>
            <div className="col-md-12">
                <p>Display name on website: <input
                                name="Language"
                                placeholder={currentPage.name}
                                value={''}
                                // onChange={(e) => handleInputChange(index, '', e.target.value)}
                            /></p>
                <p>Page's language:                 <input
                                name="Language"
                                placeholder={currentPage.language}
                                value={''}
                                // onChange={(e) => handleInputChange(index, '', e.target.value)}
                            /></p>
            </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <CKEditor
                        editor={ClassicEditor}
                        data={data}
                        onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            setData(editor.getData());
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <button className="btn btn-primary" style={{height:"5vh", marginTop: "2vh", marginBottom:"2vh"}}onClick={handleSubmit}>Click to update <b>{currentPage.page}</b></button>

            </div>
        </>

    )
}

export default Pages;