import {useEffect, useState} from 'react';
import {Component} from './Index.js';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/react';
import axios from 'axios';
import {Markup} from 'interweave';

import img2 from '../design/images/frontpage/impression/img2.jpeg';

import '../design/css/leden.css'


const Collapse = ({children, title, page}) => {
    const [visible, setVisible] = useState(false)
    const [text, setText] = useState("");

    useEffect(() => {
	console.log(localStorage.getItem('host'));
	axios.get(`${localStorage.getItem('host')}/api/getText/${page}/${localStorage.getItem('language')}`)
          .then(response => {
            setText(response.data[0].text);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    }, []);

    return (
    <>
        <CButton style={{maxWidth:"90%", backgroundColor:"#376cd2", marginTop:"1vh", marginLeft:"auto", marginRight:"auto", color:"white"}} onClick={(event) => {event.preventDefault(); setVisible(!visible)}}>{title}</CButton>
        <CCollapse visible={visible}>
        <CCard style={{maxWidth:"90%", marginLeft: "auto", marginRight:"auto"}} className="mt-3">
            <CCardBody style={{maxWidth:"90%"}}>
                <Markup content={text}></Markup>
            </CCardBody>
        </CCard>
        </CCollapse>
    </>
    );
}


const Leden = () => {

    const [generalPages, setGeneralPages] = useState([]);
    
    const fetchGeneralPages = () => {
        axios.get(`${localStorage.getItem('host')}/api/getGeneralPages`)
          .then(response => {
            setGeneralPages(response.data);
            console.log("Length is " + generalPages.length);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    };
    
    useEffect(() => {
        fetchGeneralPages();
    }, []);

            // <Collapse title={"DWF"} page={"dwf"}/>
            // <Collapse title={"Wedstrijd verzetten"} page={"verzetten"}/>
            // <Collapse title={"Vertrouwenspersoon"} page={"vertrouwenspersoon"}/>
            // <Collapse title={"Declaratieformulier"} page={"declaratielink"}/>
            // <Collapse title={"Fluitschema"} page={"fluitschema"}/>

    return (
        <Component title={localStorage.getItem('language') === "EN" ? "Members info" : "Leden info"} img={img2} component={<>
        {generalPages.length > 0 && generalPages.map((item, index) => (
            item.language === localStorage.getItem('language') &&
            <Collapse title={item.name} page={item.page}/>))}
        </>}/>

    );
}


export default Leden;
