import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from 'react';
import '../design/css/teams.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Component} from "./Index";

import axios from 'axios';


// Code snippet from Danziger's Stack Overflow answer:
// https://stackoverflow.com/questions/59595700/how-to-make-a-react-component-fade-in-on-scroll-using-intersectionobserver-but
const FadeInSection = ({
                           children, type, number, img
                       }) => {
    const domRef = useRef();
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setVisible(true);
                observer.unobserve(domRef.current);
            }
        });

        observer.observe(domRef.current);

        return () => observer.disconnect();
    }, []);

    return (<section id={type + number} style={{
        marginBottom: "75px",
        objectFit: "fill",
        height: "40vh",
        maxHeight: "300px",
        maxWidth: "500px",
        overflow:"hidden",
        borderRadius:"10px",
        alignItems:"center"
    }} ref={domRef} className={isVisible ? ' is-visible teams' : 'teams'}>{children}</section>);
};


const Teams = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${localStorage.getItem('host')}/api/getTeams`)
          .then(response => {
            setData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);

    const items = data.map((item, index) => (
        <>
            <Col lg={4} md={6} sm={12}>
                <FadeInSection key={index} type={item.type} number={item.nummer} img={item.type[0] + item.nummer + ".png"}>
                    <Link  to='/team' state={{ID: `${item.ID}`, IMG: `${item.type[0] + item.nummer + ".png"}`, TYPE: `${item.type}`, NUMBER: `${item.nummer}`}}>
                        <div className="teamDiv">{item.type}  {item.nummer}</div>
                        <img className={"imageHover"} src={require(`../design/images/teams/${imageExists(item) && item.type[0] + item.nummer + ".png" || 
                        !imageExists(item) && "UvO_logo.jpeg"}`)} style={{width:"100%"}}></img>
                    </Link>
                </FadeInSection>
            </Col>
        </>
    ));

    return (

        <Component component={
            <Row style={{paddingTop:"3vh"}}>
                {items}
            </Row>}/>
    );


}

function imageExists(team) {

    try {
        require(`../design/images/teams/${team.type[0] + team.nummer + ".png"}`);
        return true;
    } catch (error) {
        return false;
    }
}

export default Teams;
