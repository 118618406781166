import '../design/css/index.css'
import '../App.css';

const Whoops = () => {
    return (
        <div style={{minHeight: "25vh"}}>
            <p>OOPSIE WOOPSIE!! Uwu We made a fucky wucky!! A wittle fucko boingo! The code monkeys at our headquarters
                are working VEWY HAWD to fix this!</p>
        </div>
    );
}


export default Whoops;