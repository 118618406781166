import React, {useEffect, useRef, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import usclogo from '../design/USCLogo.png';
import sklogo from '../design/sklogo.png';
import ssalogo from '../design/ssalogo.png';
import anime from "animejs/lib/anime.es.js"
import vulogo from '../design/vulogo.png';
import '../design/css/utils.css';
import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const maxWidth = 1000;

// If you want to add a new item to the dropdown menu, specify here the title of the menu and all the pages in the array
// Pages are linked through the dutch page names, so be sure to use these in the router component
// var pagesDict = {
//     "Shop": {"EN": ["merch", "tickets"], "NL": ["merch", "tickets"]},
//     "Info": {
//         "EN": ["sign up", "general", "commitees", "articles of association"],
//         "NL": ["inschrijven", "algemeen", "commissies", "statuten"]
//     },
//     "Teams": {"EN": ["teams", "training"], "NL": ["teams", "training"]}
// }


const useMediaQuery = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sideBar, setSideBar] = useState(false);

    // Function to determine which component to render based on the window width
    const updateComponent = () => {
        if (windowWidth > maxWidth) {
            setSideBar(false);
        } else {
            setSideBar(true);
        }
    };

    // Update the window width when the component mounts and when it resizes
    useEffect(() => {
        updateComponent();
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            updateComponent(); // Re-render based on updated window width
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    return sideBar;
};


const mystyle = {
    textDecoration: "none",
    fontSize: "16px",
    textTransform: "uppercase",
    color: "white"
};

const dropDownText = {
    textAlign: "center",
    textDecoration: "none",
    color: "black",
    fontSize: "13px",
    textTransform: "uppercase",
    marginTop: "auto",
    marginBottom: "auto"
};

export function DropDown({title, buttons, children, black}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const mystyle = {
        textDecoration: "none",
        fontSize: "16px",
        textTransform: "uppercase",
        color: black ? "black" : "white",
    };

    return (
        <div
            className={`dropdown${isOpen ? ' show' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <p
                type="button"
                className="dropdown-toggle"
                style={mystyle}
            >
                {title}
            </p>
            <div
                className={`dropdown-menu${isOpen ? ' show' : ''}`}
                style={{height: "max-content"}}
            >
                {children}
            </div>
        </div>
    );
}

function DropdownList({page, buttons}) {

    return (
        <>
            {buttons.map((item, index) => (
                <React.Fragment key={index}>
                    <Link to={'/' + item.page.toString()} style={dropDownText}><p>{item.name.toString()}</p></Link>
                    {index !== buttons.length - 1 && <div className="dropdown-divider"></div>}
                </React.Fragment>))}
        </>
    );

}

export function Content({children}) {
    return (
        <div className="content">
            {children}
        </div>
    )
}


export function HeaderSpace() {
    return (
        <div className="headerSpace"/>
    )
}

export function Header() {

    const navigate = useNavigate();
    const isBreakpoint = useMediaQuery(maxWidth)
    const {pathname} = useLocation();
    const [pagesGroups, setPagesGroups] = useState([]);
    const [pages, setPages] = useState({});

    const lan = localStorage.getItem('language');
    const isEn = lan === "EN";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        axios.get(`${localStorage.getItem('host')}/api/getPagesGroups`)
          .then(response => {
            setPagesGroups(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    }, []);

    useEffect(() => {
        // Fetch pages for each groupId
        const fetchPages = async (groupId) => {
            try {
                console.log("Fetching pages with ID " + groupId + " and lan " + lan);
                const response = await axios.get(`${localStorage.getItem('host')}/api/getPagesByGroupId/${groupId}/${lan}`);
                setPages(prevPages => ({
                    ...prevPages,
                    [groupId]: response.data
                }));
                console.log(response);
            } catch (error) {
                console.error(`Error fetching pages for groupId ${groupId}:`, error);
            }
        };

        if (pagesGroups.length > 0) {
            pagesGroups.forEach(group => {
                fetchPages(group.ID);
            });
        }
    }, [pagesGroups]);

    return (
        <>
            <div className="header">
                <Sidebar/>
                {!isBreakpoint &&
                    <>
                        <div className="headerItems">
                            {pagesGroups.map((group, i) => (<>
                                {pages[group.ID] && pages[group.ID].length == 1 && <Link style={mystyle} to={`/${pages[group.ID][0].name.toString() == "Home" ? "" : pages[group.ID][0].page.toString()}`}>{pages[group.ID][0].name.toString()}</Link>}
                                {pages[group.ID] && pages[group.ID].length > 1 &&
                                // pages[group.ID].map((page, i) => (
                                    <React.Fragment key={i}>
                                        <DropDown title={isEn ? group.groupNameNL : group.groupNameEN}>
                                        {/* <DropdownList page={page} buttons={pages[group.groupId]}/> */}
                                            <DropdownList buttons={pages[group.ID]}/>
                                        </DropDown>
                                    </React.Fragment>
                                    // ))
                                }
                                
                                
                                
                            </>
                                // <div key={group.groupId}>
                                //     <h2>{group.groupName}</h2>
                                //     {pages[group.groupId] ? (
                                //         <ul>
                                //             {pages[group.groupId].map(page => (
                                //                 <li key={page.pageId}>{page.pageTitle}</li>
                                //             ))}
                                //         </ul>
                                //     ) : (
                                //         <p>Loading pages...</p>
                                //     )}
                                // </div>
                            ))}
                            {/* <Link to="/" style={mystyle}><p>Home</p></Link>
                            {Object.entries(pagesDict).map(([key, value], index) =>
                                <React.Fragment key={index}>
                                    <DropDown title={key}>
                                        <DropdownList page={key} buttons={value[localStorage.getItem('language')]}/>
                                    </DropDown>
                                </React.Fragment>
                            )}
                            <Link to="/contact" style={mystyle}><p>Contact</p></Link> */}
                            <img style={{marginTop: "4px", width: "40px", height: "20px", cursor: "pointer"}}
                                 onClick={() => {
                                     localStorage.setItem('language', lan === "NL" ? "EN" : "NL");
                                     window.location.reload()
                                 }} src={require(`../design/images/${lan === "EN" ? "NL" : "EN"}.png`)}/>
                        </div>
                    </>
                }
                <div className="imageText">
                    <img onClick={() => navigate('/')} style={{cursor: "pointer"}}
                         src={require(`../design/UvO_logo.jpeg`)}></img>
                </div>
            </div>
            <Outlet/>
        </>
    )
}

export function Overlay({children}) {

    const myComponentRef = useRef(null);

    const animation = anime({
        targets: myComponentRef.current, // Target the component with the ref
        width: '100vw', // Target width
        duration: 2000, // Animation duration in milliseconds
        easing: 'spring(1, 50, 10, 0)', // Easing function
    });

    return (
        <div className='overlay' ref={myComponentRef}>
            {children}
        </div>
    )
}

export function Sidebar() {

    const isBreakpoint = useMediaQuery(maxWidth)

    const [open, setOpen] = useState(false);

    const handleMenuClick = () => {
        console.log(open);
        setOpen(!open);
    }

    const lan = localStorage.getItem('language');
    const isEn = lan === "EN";

    return (
        <> {isBreakpoint &&
            <div className={`sidebar ${open ? 'open' : ''}`}>
                <div className="menuButton" onClick={handleMenuClick}></div>
                {open && <div className={`menuItems show`}>
                    <Link to="/" style={mystyle} onClick={() => setOpen(!open)}><p>Home</p></Link>
                    <Link to="/inschrijven" style={mystyle} onClick={() => setOpen(!open)}>
                        <p>{isEn ? "Join uvo" : "Lid worden"}</p></Link>
                    <Link to="/algemeen" style={mystyle} onClick={() => setOpen(!open)}>
                        <p>{isEn ? "For members" : "Voor leden"}</p></Link>
                    <Link to="/commissies" style={mystyle} onClick={() => setOpen(!open)}>
                        <p>{isEn ? "Commitees" : "Commissies"}</p></Link>
                    <Link to="/statuten" style={mystyle} onClick={() => setOpen(!open)}><p>{isEn ? "Articles of association" : "Statuten"}</p></Link>
                    <Link to="/teams" style={mystyle} onClick={() => setOpen(!open)}><p>Teams</p></Link>
                    <Link to="/tickets" style={mystyle} onClick={() => setOpen(!open)}><p>Tickets</p></Link>
                    <Link to="/merch" style={mystyle} onClick={() => setOpen(!open)}><p>Merch</p></Link>
                    <Link to="/training" style={mystyle} onClick={() => setOpen(!open)}><p>Training</p></Link>
                    <Link to="/contact" style={mystyle} onClick={() => setOpen(!open)}><p>Contact</p></Link>
                    <img style={{marginTop: "4px", width: "40px", height: "20px", cursor: "pointer"}} onClick={() => {
                        localStorage.setItem('language', lan === "NL" ? "EN" : "NL");
                        window.location.reload()
                    }} src={require(`../design/images/${lan === "EN" ? "NL" : "EN"}.png`)}/>
                </div>
                }
            </div>
        }
        </>
    )

}

export function Footer() {
    return (
        <div className='footer'>
            <Row><p style={{textAlign: "center"}}><b>Sponsored by:</b></p></Row>
            <Row>
                <Col sm={4}>
                    <a href="https://www.studentensport.amsterdam/">
                        <img src={ssalogo}></img>
                    </a>
                </Col>
                <Col sm={4}>
                    <img src={sklogo}></img>

                </Col>
                <Col sm={4}>
                    <a href="https://www.uscsport.nl/">
                        <img src={usclogo}></img>
                    </a>
                </Col>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <a href="https://sportcentrumvu.nl/">
                        <img src={vulogo}></img>
                    </a>
                </Col>
                <Col sm={4}></Col>

            </Row>
            <Row><p style={{textAlign: "center"}}>© UvO Amsterdam. Alle rechten voorbehouden.</p></Row>
        </div>
    )
}
