import {Component} from './Index.js';
import '../design/css/index.css'
import '../App.css';

import statutenPdf from './Statuten.pdf';

import img6 from '../design/images/slide6.jpg';

import React from 'react';


const Statuten = () => {
  return (
    <Component img={img6} title={"Statuten"} component={<>
        <object class="pdf" 
            data={statutenPdf}>
    </object>
    </>}/>
  );
};

export default Statuten;